<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row justify-content-between" v-if="isQuestion == false">
          <div class="col-10">
            <div class="row mb-3">
              
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-10">
            <button @click="info" class="btn btn-sm btn-primary" v-if="isQuestion == false">
                Panduan
            </button>
          </div>
          <div class="col-2">
            <div class="row mb-3">
              <button @click="submit" class="btn btn-sm btn-primary" v-if="isQuestion == false">
                Submit All Tables
              </button>
              <button @click="update" class="btn btn-sm btn-primary" v-else>
                Update All Tables
              </button>
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <div class="table-container">
                <div v-for="group in listTemplateGroup" :key="group.name" class="table-wrapper col-5">
                  <table>
                    <caption>Group {{ group.name }}</caption>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Question</th>
                        <th>Sub Group</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, rowIndex) in group.questions" :key="rowIndex">
                          <td>{{ rowIndex + 1 }}</td>
                          <td>
                              <input v-model="row['text' + group.name + rowIndex]" type="text" class="form-control">
                          </td>
                          <td>
                            <!-- display corresponding sub data -->
                            {{ group.subName[rowIndex] }}
                          </td>
                      </tr>
                      <!-- <tr v-for="(row, rowIndex) in group.questions" :key="rowIndex" v-else>
                        <td>{{ rowIndex + 1 }}</td>
                        <td>
                          <input v-model="row['text' + group.name + rowIndex]" type="text" class="form-control">
                        </td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import * as data from "../../model/test-bundle";
import { uploadImage } from "@/utils/fileUpload";
import FileSaver from "file-saver";

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
      },
      items: null,
      sub:[],
      itemsTotal: 0,
      listTemplateGroup: [],
      listTemplateSubGroup: [],
      templateid: null,
      testId: null,
      isQuestion: false,
      jmlPertanyaan: 12,
      row: null
    };
  },
  methods: {
    getSubGroupByIndex(index) {
        return this.listTemplateSubGroup[index] || { name: 'N/A', subId: null };
    },
    buildSubGroupData(subGroup, index) {
      return {
        id: subGroup.id,
        name: subGroup.name,
        questions: Array.from({ length: 12 }, (_, rowIndex) => {
          return { [`text${subGroup.name}${rowIndex}`]: rowIndex + 1 };
        })
      };
    },
    submit() {
      var loading = this.$loading.show();
      const questions = {};

      this.listTemplateGroup.forEach((group) => {
        const groupInput = group.questions.map((row, rowIndex) => row['text' + group.name + rowIndex]);
        questions[group.name] = groupInput;
      });

      const dataKirim = {
        templateId: this.templateid,
        groupId: this.testId,
        dataGroup: this.listTemplateGroup,
        dataSubGroup: this.sub.map((subGroup, index) => this.buildSubGroupData(subGroup, index)), // Using buildSubGroupData to get the questions format
        dataQuestions: questions
      };

      console.log(dataKirim);
      this.$store
        .dispatch("test_question/addQuestion", dataKirim)
        .then(() => {
          this.$toast.success("Berhasil menambahkan data");
          loading.hide();
          this.$router.push({ path: 'test-group' });
        })
        .catch((e) => {
          loading.hide();
        });
    },
    update() {
        var loading = this.$loading.show();
        const questions = {};
        const questionsByGroup = {};

        // Initialize each group with an empty array
        this.listTemplateGroup.forEach((data) => {
            questionsByGroup[data.name] = [];
        });

        // Populate the questionsByGroup with item IDs
        this.items.forEach((item) => {
            this.listTemplateGroup.forEach((data) => {
                if (item.test_template_groups_id == data.id) {
                    questionsByGroup[data.name].push(item.id);
                }
            });
        });

        // Populate the questions with question text
        this.listTemplateGroup.forEach((group) => {
            const groupInput = group.questions.map((row, rowIndex) => row['text' + group.name + rowIndex]);
            questions[group.name] = groupInput;
        });

        const dataUpdate = {
            templateId: this.templateid,
            groupId: this.testId,
            dataGroup: this.listTemplateGroup,
            dataSubGroup: this.sub.map((subGroup, index) => this.buildSubGroupData(subGroup, index)), // Assuming you still want to use this
            dataQuestions: questions,
            dataQuestionsId: questionsByGroup  // Updated this to be the new questionsByGroup
        };

        console.log(dataUpdate);

        this.$store
            .dispatch("test_question/updateQuestion", {
                id: this.testId.toString(),
                data: dataUpdate,
            })
            .then(() => {
                this.$toast.success("Berhasil mengupdate data");
                loading.hide();
                this.$router.push({ path: 'test-group' });
            })
            .catch((e) => {
                console.log(e)
                this.$toast.error("Error updating data");
                loading.hide();
            });
    },
    getData() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("test_question/getQuestion", this.testId)
        .then((resp) => {
          this.items = resp.data;
          this.itemsTotal = resp.total;

          if (this.items !== "kosong") {
            this.isQuestion = true;
            const rows = new Array(parseInt(this.itemsTotal)).fill(null);

            this.listTemplateGroup.forEach((group) => {
              
              const matchingItems = this.items.filter(item => {
                return Number(item.test_template_groups_id) === Number(group.id)
              });

              if (matchingItems.length > 0) {
                const numRows = Math.min(this.itemsTotal, matchingItems.length);
                group.questions = Array.from({ length: 12 }, (_, rowIndex) => {
                    if (rowIndex < matchingItems.length) {
                        return { ['text' + group.name + rowIndex]: matchingItems[rowIndex].question };
                    } else {
                        return {};
                    }
                });
              } else {
                group.questions = [...rows];
              }
            });
          }
          loading.hide();
        })
        .catch((e) => {
          alert("gagal mengambil data  \n", e);
          console.log(e)
          loading.hide();
        });
    },
    getTemplateSubData() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("test_question/getTemplateSub", this.templateid)
        .then((resp) => {
          this.sub = resp.data;

          const subNameMap = [];
          this.sub.forEach((sub) => {
            subNameMap.push(sub.name);
            return {
              id: sub.id,
              name: sub.name,
            }
          });

          this.listTemplateGroup = this.listTemplateGroup.map((group) => {
            return {
              ...group,
              subName: subNameMap,
            };
          });

          loading.hide();
        })
        .catch((e) => {
          this.$toast.error("Failed to fetch data \n", e);
          loading.hide();
        });
    },
    getDataGroup() {
      var loading = this.$loading.show();

      this.$store
        .dispatch("template/getTemplateGroup", this.templateid)
        .then((resp) => {
          // console.log(this.listTemplateGroup);
          this.listTemplateGroup = resp.data.map(group => ({
              id: group.id,
              name: group.name,
              questions: Array.from({ length: 12 }, () => ({})),
              subName: [],
          }));
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e);
          loading.hide();
        });
    },
    info() {
        this.$swal.fire({icon: 'info', html: 'Silahkan masukan jumlah pertanyaan yang akan di buat!'});
    }
  },
  computed: {
    displayedSubGroups() {
      return this.sub.filter((subData, index) => this.listTemplateGroup[index]);
    }
  },
  mounted() {
    // this.getData();
  },
  created() {
    this.templateid = this.$route.query.templateid;
    this.testId = this.$route.query.testId;
    this.getDataGroup();
    this.getData();
    this.getTemplateSubData()

    if (this.listTemplateGroup.length !== this.listTemplateSubGroup.length) {
        console.warn('Mismatch in group and sub-group sizes');
    }
    // console.log(templateid);
    // console.log(testId);
  },
};
</script>

<style scoped>
.table-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.table-wrapper {
  width: calc(50% - 20px);
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  text-align: center;
}

caption {
  font-weight: bold;
  text-align: center;
  padding: 10px;
  position: relative;
  top: -10px;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

@media (max-width: 768px) {
  .table-container {
    flex-direction: column;
    justify-content: initial;
    margin-left: auto;
    margin-right: auto;
  }

  .table-wrapper {
    width: 100%;
  }
}
</style>